<template>
    <div>
        <el-form ref="queryForm" class="query-box"  v-model="queryParams" label-width="110px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="机具SN号" prop="name" label-width="74px">
                        <el-input v-model="queryParams.beginSn"
                                  clearable
                                  placeholder="请输入机具SN号"
                                  style="width: 48%"
                                  size="small" @keyup.enter.native="handleQuery"/>
                                  <span style="display: inline-block;width: 4%;text-align: center;">-</span>
                        <el-input v-model="queryParams.endSn"
                                  clearable
                                  placeholder="请输入机具SN号"
                                  style="width: 48%"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="入库时间">
                        <el-date-picker v-model="createTime"
                                        size="small" value-format="yyyy-MM-dd"
                                        type="daterange" range-separator="-"
                                        style="width: 100%"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="终端状态" prop="name">
                        <el-select v-model="queryParams.status" placeholder="请选择终端状态" size="small" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="已入库" value="1"></el-option>
                            <el-option label="已绑定" value="2"></el-option>
                            <el-option label="已下发" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="机具型号" prop="name" label-width="74px">
                        <el-select v-model="queryParams.hardwareNo" size="small" placeholder="请选择机具型号" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="(item,index) in hardwareList" :label="item.hardwareName" :value="item.hardwareNo" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="活动名称" prop="name">
                        <el-select v-model="queryParams.activityNo" size="small" placeholder="请选择活动名称" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="(item,index) in activityList" :label="item.activityName" :value="item.activityNo" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="活动类型" prop="name">
                        <el-select v-model="queryParams.policyNo" size="small" placeholder="请选择活动类型" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="(item,index) in policyList" :label="item.policyName" :value="item.policyNo" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="商户编号" prop="name">
                        <el-input v-model="queryParams.merchantNo"
                                  clearable
                                  placeholder="请输入商户编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="商户名称" prop="name" label-width="74px">
                        <el-input v-model="queryParams.merSubName"
                                  clearable
                                  placeholder="请输入商户名称"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="一级机构编号" prop="name">
                        <el-input v-model="queryParams.oneOrgNo"
                                  clearable
                                  placeholder="请输入一级机构编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="一级代理商编号" prop="name">
                        <el-input v-model="queryParams.oneAgentNo"
                                  clearable
                                  placeholder="请输入一级代理商编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="直属代理商编号" prop="name">
                        <el-input v-model="queryParams.orgNo"
                                  clearable
                                  placeholder="请输入直属代理商编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="渠道" prop="channelNo" label-width="74px">
                        <el-select v-model="queryParams.channelNo"
                                   placeholder="业务线" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option v-for="(item,key) of dicts.CHANNEL" :key="key" :label="item" :value="key" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="机具类型" prop="hardwareType">
                        <el-select v-model="queryParams.hardwareType"
                                   placeholder="请选择机具类型" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option label="电签" value="1"/>
                            <el-option label="传统" value="2"/>
                            <el-option label="二维码" value="3"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                  <el-col :span="6">
                    <el-form-item label="绑定时间">
                        <el-date-picker v-model="bindTime"
                                        size="small" value-format="yyyy-MM-dd"
                                        type="daterange" range-separator="-"
                                        style="width: 100%"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery" v-permission="'TERMINAL_INFO_QUERY'">
                    查询
                </el-button>
                <el-button size="mini" type="primary"
                           @click="handleInsert" v-permission="'TERMINAL_INFO_INSERT'">
                    入库
                </el-button>
                <el-button size="mini" type="primary"
                           @click="handleUpdate" v-permission="'TERMINAL_INFO_UPDATE'">
                    批量修改
                </el-button>
                <el-button type="primary" size="mini"
                           @click="handleDelete"
                           v-permission="'TERMINAL_INFO_DELETE'">
                    批量删除
                </el-button>
                <el-button type="warning" icon="el-icon-download" size="mini"
                           @click="handleExport"
                           v-permission="'TERMINAL_INFO_EXPORT'">
                    导出
                </el-button>
            </el-col>
        </el-row>


    </div>
</template>

<script>
    import {TerminalApi,PolicyApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            },
            pageNo:{
                type: Number,
                default: 1
            },
            pageSize:{
                type: Number,
                default: 10
            },
        },
        data() {
            return {
                queryParams: {},
                createTime: [],
                bindTime:[],
                updateDateRange: [],
                showCustomForm: false,
                hardwareList:[],
                activityList:[],
                policyList:[]
            }
        },
        created() {
            TerminalApi.hardwareInfo.getHardwareList()
                .then(res=>{
                    if(res.success){
                        this.hardwareList = res.data
                    }
                })
            TerminalApi.queryInfo.getActivityInfo()
                .then(res=>{
                    if(res.success){
                        this.activityList = res.data
                    }
                })
            PolicyApi.policyInfo.getPolicyList()
                .then(res=>{
                    if(res.success){
                        this.policyList = res.data
                    }
                })
        },
        activated(){
            this.handleQuery()
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createTime = [];
                this.bindTime= [];
                this.updateDateRange = [];
                this.commitChange();
            },
            handleAdd(){
                this.showCustomForm = true;
            },
            handleExport() {
                this.$confirm("确定导出",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await TerminalApi.terminalInfo.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                if(!this.createTime){
                    this.createTime =[]
                }
                let [beginTime, endTime] = this.createTime;
                this.queryParams.beginTime = beginTime && (beginTime + ' 00:00:00');
                this.queryParams.endTime = endTime && (endTime + ' 23:59:59');

                let [startUpdateTime, endUpdateTime] = this.updateDateRange;
                this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
                this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
                if(!this.bindTime){
                    this.bindTime=[];
                }
                let [bindBeginTime,bindEndTime] =this.bindTime;
                this.queryParams.startBindTime=bindBeginTime && (bindBeginTime + ' 00:00:00');
                this.queryParams.endBindTime=bindEndTime && ( bindEndTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
            handleInsert(){
                this.$router.push({
                    name:'terminalInsert',
                    query:{
                        type:'insert'
                    }
                })
            },
            handleUpdate(){
                this.$router.push({
                    name:'terminalBatchUpdate',
                    query:{
                        type:'update'
                    }
                })
            },
            handleDelete(){
                this.$router.push({
                    name:'terminalDelete',
                })
            },
        }
    }
</script>

<style scoped>

</style>
