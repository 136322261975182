<template>
    <div>
        <el-table stripe v-loading="loading" :data="dataList">
            <el-table-column label="商户编号" prop="merchantNo" min-width="120" :show-overflow-tooltip="true" />
            <el-table-column label="渠道" prop="channelNo" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ scope.row.channelNo | channelFM }}
                </template>
            </el-table-column>
            <el-table-column label="机具SN号" prop="sn" min-width="160" :show-overflow-tooltip="true" />
            <el-table-column label="终端号" prop="terNo" min-width="120" :show-overflow-tooltip="true" />
            <el-table-column label="入库时间" prop="createTime" min-width="180" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ scope.row.createTime | formatTime }}
                </template>
            </el-table-column>
            <el-table-column label="绑定时间" prop="bindTime" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ scope.row.bindTime | formatTime }}
                </template>
            </el-table-column>
            <el-table-column label="机具状态" prop="status" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button type="primary" plain size="mini" class="button-status" 
                    v-if="scope.row.status">{{scope.row.status | terminalStatusFM}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="机具型号" prop="hardwareNo" min-width="120" :show-overflow-tooltip="true" />
            <el-table-column label="机具类型" prop="hardwareType" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{ scope.row.hardwareType | hardwareTypeFM }}
                </template>
            </el-table-column>
            <el-table-column label="活动名称" prop="activityName" min-width="120" :show-overflow-tooltip="true" />
            <el-table-column label="活动类型" prop="policyName" min-width="120" :show-overflow-tooltip="true" />
            <el-table-column label="商户名称" prop="merSubName" min-width="120" :show-overflow-tooltip="true" />
            <el-table-column label="一级代理商" prop="oneAgentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.oneAgentNo}} - {{scope.row.oneAgentName}}
                </template>
            </el-table-column>
            <el-table-column label="直属代理商" prop="oneAgentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.agentNo}} - {{scope.row.agentName}}
                </template>
            </el-table-column>
            <!-- 操作按钮 -->
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="200" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" v-if="scope.row.status == '1'" type="text" @click="handleUpdate(scope.row)"
                        v-permission="'TERMINAL_INFO_UPDATE'">
                        <i class="el-icon-edit"></i>
                        修改
                    </el-button>
                    <el-button size="mini" type="text" v-if="scope.row.status == '2'" @click="handleUnBind(scope.row)"
                        v-permission="'TERMINAL_INFO_UPDATE'">
                        <i class="el-icon-s-fold"></i>
                        解绑
                    </el-button>
                    <!-- <el-button size="mini" type="text" v-if="scope.row.status == '1'" @click="handleBind(scope.row)" v-permission="'TERMINAL_INFO_UPDATE'">绑定</el-button> -->
                    <el-button size="mini" type="text" v-if="scope.row.status == '1'" @click="handleDelete(scope.row)"
                        v-permission="'TERMINAL_INFO_DELETE'">
                        <i class="el-icon-delete"></i>
                        删除
                    </el-button>
                    <el-button size="mini" type="text" @click="showLog(scope.row)">
                        <i class="el-icon-edit-outline"></i>
                        日志
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize"
            @pagination="getList" />
        <CustomForm :dicts="dicts" :on-fresh="showCustomForm" :selectRow="selectRow"
            @on-close="handlerCustomFormOnclose">
        </CustomForm>
        <Log :data="logInfo" :on-fresh="showLogDialog" @on-close="onClose"></Log>
    </div>
</template>

<script>
import { TerminalApi } from '@/api';
import CustomForm from "./CustomForm";
import Log from "./Log";
export default {
    name: "ToolBar",
    components: { CustomForm, Log },
    props: {
        dicts: {
            type: Object,
            default: function () {
                return {}
            }
        },
        queryParams: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            loading: false,
            total: 0,
            dataList: [],
            selectRow: {},
            showCustomForm: false,
            isAdd: false,
            logInfo: [],
            showLogDialog: false
        }
    },
    mounted() {
    },
    watch: {
        queryParams: function () {
            this.pageNo = 1;
            this.getList();
        },
    },
    methods: {
        async getList() {
            this.loading = true;
            let result = await TerminalApi.terminalInfo.list(this.pageNo, this.pageSize, this.queryParams);
            this.loading = false;
            this.total = result.count || 0;
            this.dataList = result.data || [];
            this.$emit('getPageNo', this.pageNo, this.pageSize);
        },
        handlerCustomFormOnclose() {
            this.showCustomForm = false;
            this.getList();
        },
        handleStatusChange(row) {
            let text = row.status === "DISABLE" ? "禁用" : "启用";
            this.$confirm("是否确定" + text + "?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let result = await TerminalApi.terminalInfo.edit(row.id, {
                    state: row.state
                });
                if (result.success) {
                    this.getList();
                }
            }).catch(() => this.getList());
        },
        async handleUpdate(row) {
            this.$router.push({
                name: 'terminalUpdate',
                query: {
                    item: JSON.stringify(row)
                }
            })
        },
        handleDelete(row) {
            this.$confirm("是否确定删除编号为" + row.sn + "的数据项?",
                "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let result = await TerminalApi.terminalInfo.remove(row.sn);
                if (result.success) {
                    this.Message.success("删除成功");
                    this.getList();
                } else {
                    this.Message.error(result.message);
                }
            });
        },
        handleUnBind(row) {
            this.$confirm("是否确定解绑编号为" + row.sn + "的机具?",
                "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(async () => {
                let result = await TerminalApi.terminalInfo.unbindSn(row.sn);
                if (result.success) {
                    this.Message.success("解绑成功");
                    this.getList();
                } else {
                    this.Message.error(result.message);
                }
            });
        },
        handleBind(row) {
            this.showCustomForm = true;
            this.selectRow = row;
        },
        async showLog(row) {
            this.showLogDialog = true;
            let result = await TerminalApi.terminalInfo.queryTerminalOperRecord(1, 20, {
                sn:row.sn
            });
            console.log(result);
            const types={
                'WAREHOUSING':'入库',
                'ISSUE':'下发',
                'RECOVER':'回收',
                'UNBIND':'解绑',
                'BIND':'绑定'
            }
            if(result.data.list){
                result.data.list.forEach((item)=>{
                    item.operType=types[item.operType];
                });
            }
            this.logInfo = result.data.list;
        },
		onClose(){
			this.showLogDialog=false;
		},
    }
};
</script>

<style scoped></style>
